'use client';

import { useHeaderNavLinks } from '@/config';
import { Drawer, DrawerContent, DrawerTrigger } from '@/ui/drawer';
import { Button, cn, Search } from '@tajdid/tajdid-ui';
import { XCloseIcon } from '@tajdid/tajdid-ui/icons';
import { useEffect, useRef, useState } from 'react';
import { usePathname } from '@/i18n/routing';
import { Link } from '@/i18n/routing';
import Image from 'next/image';
import { User } from 'lucia';
import { Logo } from '../logo';
import { FilePathUtils } from '@/utils/file-path-utils';
import { Menu02Icon } from 'hugeicons-react';

const applicablePaths = ['/', '/courses', '/my-classes', '/profile'];

type NavbarDetailsProps = {
  user?: User | null;
  handleLogout: () => void;
};

export const hideNavbar = [''];

export const isResourcePage = (pathName: string) => {
  const pattern = /^\/my-curriculum\/[^\/]+\/resources\/.*/;
  return pattern.test(pathName);
};

export default function NavbarDetails({ user }: NavbarDetailsProps) {
  const pathName = usePathname();
  const [isOpen, setIsOpen] = useState(false);
  const headerNavLinks = useHeaderNavLinks();

  const [isSticky, setIsSticky] = useState(false);
  const sentinelRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsSticky(!entry.isIntersecting);
      },
      { threshold: [1] },
    );

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => {
      if (sentinelRef.current) {
        observer.unobserve(sentinelRef.current);
      }
    };
  }, []);

  return (
    <>
      <div ref={sentinelRef} id="sentinel"></div>
      {!hideNavbar.includes(pathName) && (
        <nav
          className={`header ${isSticky ? 'sticky top-0 z-50 bg-white shadow-xs lg:static' : 'sticky top-0 z-50 bg-transparent shadow-xs lg:static transition'}`}
        >
          <div className="container  gap-4 lg:py-7">
            <div className="hidden lg:flex gap-5 justify-between items-center ">
              <div className="relative">
                <div className="absolute -top-[32px] -left-[2px] -z-10 ">
                  <p className="px-[10px] py-[7px] bg-[#F6F6FA] rounded-[6px] w-fit font-bold text-gray-700 ">
                    Beta
                  </p>
                </div>
                <Logo variant="color" width={175} height={34} />
              </div>
              <div className="w-[280px]">
                <Search
                  onClick={() => {}}
                  placeholder="Search for classes, skills, teachers"
                />
              </div>
              <div className="flex items-center gap-2 ml-3">
                {headerNavLinks.map(item => (
                  <Link
                    className={`text-base p-2 ${item.slug === pathName ? 'text-primary font-semibold' : 'text-gray-500'}`}
                    key={item.slug}
                    href={item.slug}
                  >
                    {item.title}
                  </Link>
                ))}
                {!user && (
                  <Link href="/login">
                    <Button>Sign in</Button>
                  </Link>
                )}
              </div>

              {/* <Select
                onChange={e => handleLanguageChange(e)}
                value={localeActive}
                disabled={isPending}
                className="bg-white"
                fullWidth
                options={[
                  { label: 'English', value: 'en' },
                  { label: 'Bangla', value: 'bn' },
                ]}
              /> */}

              {user && (
                <Link className="flex items-center gap-2" href="/profile">
                  <Image
                    className="w-8 h-8 rounded-full"
                    height={32}
                    width={32}
                    alt="profile"
                    src={
                      user.picture
                        ? `${FilePathUtils.userProfilePath(user.id)}/${user.picture}`
                        : `/Images/user-fallback.png`
                    }
                    // src={'/Images/user-fallback.png'}
                  />
                  <p className="mt-1 text-sm font-semibold text-gray-700">
                    {user.name ?? 'Anonymous'}
                  </p>
                </Link>
              )}
            </div>

            <div
              className={cn(
                'flex items-center justify-between py-6 lg:hidden',
                isResourcePage(pathName) && 'hidden',
              )}
            >
              <div className="flex gap-4">
                <Drawer direction="left" open={isOpen} onOpenChange={setIsOpen}>
                  <DrawerTrigger>
                    <Menu02Icon />
                  </DrawerTrigger>
                  <DrawerContent>
                    <div className="mobile-menu">
                      <div className="p-3 cursor-pointer close-icon">
                        <XCloseIcon onClick={() => setIsOpen(false)} />
                      </div>
                      <div
                        className="mobile-menu-logo p-3 mt-5"
                        onClick={() => setIsOpen(false)}
                      >
                        <div className="relative">
                          <div className="absolute -top-[34px] -left-[2px] -z-10 ">
                            <p className="px-[13px] py-[9px] bg-[#F6F6FA] rounded-[6px] w-fit font-bold text-gray-700 ">
                              Beta
                            </p>
                          </div>
                          <Logo variant="color" width={175} height={34} />
                        </div>
                      </div>
                      <div
                        className={cn(
                          'p-3',
                          applicablePaths.includes(pathName),
                        )}
                      >
                        <Link
                          href="/courses"
                          className={cn(
                            'flex items-center gap-2 mb-3 text-gray-600 border-b-1 pb-3',
                            pathName === '/browse' && 'text-primary-600',
                          )}
                          onClick={() => setIsOpen(false)}
                        >
                          <p>Browse</p>
                        </Link>

                        <Link
                          href="/my-classes"
                          className={cn(
                            'flex items-center gap-2 mb-3 text-gray-600 border-b-1 pb-3',
                            pathName === '/my-classes' && 'text-primary-600',
                          )}
                          onClick={() => setIsOpen(false)}
                        >
                          <p>My Classes</p>
                        </Link>
                      </div>
                    </div>
                  </DrawerContent>
                </Drawer>
                <div className="relative">
                  <div className="absolute -top-[34px] -left-[2px] -z-10 ">
                    <p className="px-[13px] py-[9px] bg-[#F6F6FA] rounded-[6px] w-fit font-bold text-gray-700 ">
                      Beta
                    </p>
                  </div>
                  <Logo variant="color" width={175} height={34} />
                </div>
              </div>
              <div className="flex col-span-2 gap-4 justify-self-end">
                {user && (
                  <Link className="flex items-center gap-2" href="/profile">
                    <Image
                      className="w-8 h-8 rounded-full"
                      height={32}
                      width={32}
                      alt="profile"
                      src={'/Images/user-fallback.png'}
                    />
                    <p className="mt-1 text-sm font-semibold text-gray-700">
                      {user.name}
                    </p>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </nav>
      )}
    </>
  );
}
